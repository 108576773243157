// InputSubmit.js
import React from 'react';

const InputSubmit = ({ errors, buttonText, extraClasses, instructions }) => (
  <fieldset className={`form-fieldset ${extraClasses}`}>
    {errors && <div className="form-validation-error" dangerouslySetInnerHTML={{ __html: errors }} />}
    <button type="submit" className="form-submit button button-primary">{buttonText}</button>
    <p className="form-submit-instructions">{instructions}</p>
  </fieldset>
);

export default InputSubmit;

import React, { useState } from 'react';
import { useLocation } from '@reach/router'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';
import BlockParagraph from '../components/blockParagraph';
import simpleBundlesLogo from '../images/logo-simplebundles.svg';
import backofficePlaybook from '../images/fpo-backoffice-playbook.png';
import InputField from '../components/inputField';
import InputSubmit from '../components/inputSubmit';

const useQueryParams = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return params;
}

const EbookPage = () => {
  const pageTitle = 'Every Brand Dreams of Growth—Your Backend Makes or Breaks It | Simple Bundles';
  const pageImage = simpleBundlesLogo;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState('');

  const queryParams = useQueryParams();

  // Create a new URL for the redirect, appending all query params
  const baseURL = "https://www.freshlycommerce.com/backend-office-playbook-subscribed";
  const redirectURL = `${baseURL}?${queryParams.toString()}`;

  // Extract the utm_medium for the hidden field
  const referredBy = queryParams.get('utm_medium') || '';

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateURL = (url) => {
    const re = /.+\..+/;
    return re.test(String(url).toLowerCase());
  };

  const validateForm = (formData) => {
    let errors = '';
    if (!formData.get('fields_first_name')) {
      errors += 'First Name is required.<br />';
    }
    if (!formData.get('fields_last_name')) {
      errors += 'Last Name is required.<br />';
    }
    const email = formData.get('email');
    if (!email) {
      errors += 'Email Address is required.<br />';
    } else if (!validateEmail(email)) {
      errors += 'Email Address is not valid.<br />';
    }
    const url = formData.get('fields_business_website_url');
    if (!url) {
      errors += 'Business Website URL is required.<br />';
    } else if (!validateURL(url)) {
      errors += 'Business Website URL is not valid.<br />';
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const formErrors = validateForm(formData);

    if (!formErrors) {
      form.submit(); // Submit the form if validation passes
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <Layout pageTitle={pageTitle} pageImage={pageImage} intro={false} headerAnimate={false} navbarAnimate={false}>
      <Seo title={pageTitle} />

      <Hero heroText="Every Brand Dreams of Growth—Your Backend Makes or Breaks It" extraClasses="padding-bottom-none" />

      <section className="block block-section padding-top-none">
        {!formSubmitted && (
          <form
            action="https://track.bentonow.com/forms/bd2dcfe04407d1a20a3ee8e70431d8d2/$subscribed-backend-office-playbook-2024?hardened=true"
            method="POST"
            encType="multipart/form-data"
            className="block g-m-1-13 g-l-4-12 padding-none"
            onSubmit={handleSubmit}
          >
            <div className="g-m-1-13 g-l-1-13">
              <BlockParagraph
                id=""
                blockTitle=""
                blockContent="This guide helps you tackle FOMU (fear of messing up), turning operational challenges into opportunities. Evolve your processes and start the new year strong. Get the ebook directly to your inbox."
                blockLinkURL=""
                blockLinkTitle=""
                extraClasses="padding-top-none padding-bottom-large"
              />
            </div>
            <div className="g-m-1-13 form-rows">
              <div className="form-col-two">
                <InputField type="text" name="fields_first_name" placeholder="First Name" />
                <InputField type="text" name="fields_last_name" placeholder="Last Name" />
              </div>
              <InputField type="email" name="email" placeholder="Email Address" />
              <InputField type="text" name="fields_business_website_url" placeholder="Business Website URL" />
              <InputSubmit
                errors={errors}
                buttonText="Get My Free eBook"
                extraClasses="margin-top-large"
                instructions="By submitting this form you consent to receive marketing emails from the e-book sponsors. You can unsubscribe at any time by clicking on the “Unsubscribe” link at the bottom of our emails."
              />
              <InputField type="hidden" name="redirect" value={redirectURL} />
              <InputField type="hidden" name="fields_referred_by" value={referredBy} />
            </div>
          </form>
        )}
        <div className="block g-m-1-13 g-l-4-12 padding-none">
          <div className="g-m-1-13">
            <div className="margin-top-medium">
              <img className="img-width-full" src={backofficePlaybook} alt="Simple Bundles eBook" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EbookPage;

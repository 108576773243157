import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({ type, name, placeholder, extraClasses, value }) => {
  if (type === "hidden") {
    return (
      <fieldset className="form-fieldset-hidden">
        <input type="hidden" name={name} value={value} />
      </fieldset>
    );
  }

  return (
    <fieldset className={`form-fieldset ${extraClasses}`}>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        className="form-input"
      />
    </fieldset>
  );
};

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  extraClasses: PropTypes.string,
  value: PropTypes.string,
};

InputField.defaultProps = {
  placeholder: '',
  extraClasses: '',
  value: '',
};

export default InputField;
